'use client'

import { useState, useRef, HTMLAttributes, FC, memo, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import {
  StackedCarousel,
  ResponsiveContainer,
  StackedCarouselSlideProps,
} from 'react-stacked-center-carousel'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import type { Event, Host } from '@/services/cms'
import { cn } from '@/lib/utils'
import { combineNames } from '@/lib/formatter'

const MAX_VISIBLE_SLIDE = 7
const SIDE_SLIDE_SCALE_RATIO = 45 / 61

interface CarouselBannerProps extends HTMLAttributes<HTMLDivElement> {
  lists: Event[]
  autoScrollInterval?: number
  autoScroll?: boolean
}
function CarouselBanner({
  lists,
  autoScroll = true,
  autoScrollInterval = 4,
  className,
  ...props
}: CarouselBannerProps) {
  const ref = useRef<StackedCarousel>()
  const [resetTimer, setResetTimer] = useState(0)

  const onCenterSlideDataIndexChange = () => {
    if (!autoScroll) return
    setResetTimer((prev) => prev + 1)
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (autoScroll) {
      interval = setInterval(() => {
        ref.current?.goNext()
      }, autoScrollInterval * 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [autoScroll, autoScrollInterval, resetTimer])

  // invisible when the amount is not sufficient
  if (lists.length < 1) return null

  // repeat the list if the amount is only 1
  if (lists.length < 2) {
    lists = [...lists, ...lists]
  }

  if (lists.length < MAX_VISIBLE_SLIDE) {
    lists = [...lists, ...lists.slice(0, MAX_VISIBLE_SLIDE - lists.length)]
  }

  return (
    <div className={cn('relative m-auto w-full max-w-[1440px]', className)} {...props}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let slideWidth = Math.max(189, Math.min(parentWidth - 250, 372))
          let carouselHeight = (slideWidth * 244) / 189
          let carouselWidth = parentWidth
          let customScales = [
            1,
            SIDE_SLIDE_SCALE_RATIO,
            SIDE_SLIDE_SCALE_RATIO,
            SIDE_SLIDE_SCALE_RATIO,
            SIDE_SLIDE_SCALE_RATIO,
          ]
          let maskSize = 54

          if (parentWidth >= 768) {
            slideWidth = Math.max(372, Math.min(parentWidth - 400, 598))
            carouselHeight = 480
            maskSize = 106
          }
          if (parentWidth >= 1024) {
            slideWidth = Math.max(598, Math.min(parentWidth - 450, 816))
            carouselHeight = (slideWidth * 334) / 598
            maskSize = 142
            customScales = [1, 0.92, 0.92, 0.92, 0.92]
          }
          if (parentWidth >= 1440) {
            slideWidth = 816
            carouselHeight = 456
            carouselWidth = 1440
            customScales = [1, 0.93, 0.93, 0.93, 0.93]
          }

          return (
            <StackedCarousel
              height={carouselHeight}
              carouselWidth={carouselWidth}
              ref={carouselRef}
              slideComponent={(props) => <Slide {...props} maskSize={maskSize} />}
              slideWidth={slideWidth}
              data={lists}
              maxVisibleSlide={MAX_VISIBLE_SLIDE}
              customScales={customScales}
              transitionTime={180}
              className="relative m-auto"
              onActiveSlideChange={onCenterSlideDataIndexChange}
              fadeDistance={0}
            />
          )
        }}
      />
      <SideFadeOutMask />
    </div>
  )
}

export default CarouselBanner

interface SlideProps extends StackedCarouselSlideProps {
  data: Event[]
  borderRadius: number
  maskSize: number
}

export const Slide: FC<SlideProps> = memo(function Slide(props) {
  const router = useRouter()
  const { data, dataIndex, isCenterSlide, swipeTo, slideIndex, maskSize } = props

  if (!data[dataIndex]) return null

  const { image, title, logo, hosts, id } = data[dataIndex]

  const handleSwipeClick = () => {
    if (!isCenterSlide) swipeTo(slideIndex)
  }

  const handleRedirectClick = () => {
    if (isCenterSlide) router.push(`/events/detail/${id}`)
  }
  return (
    <div
      className="relative aspect-[189/244] w-full cursor-pointer rounded-[8px] transition-all duration-150 ease-in md:h-[480px] lg:aspect-[598/334] lg:h-auto lg:rounded-[16px] 2xl:h-[456px]"
      draggable={false}
    >
      <div
        id="side-slide"
        className={`absolute size-full transition-opacity duration-150 ease-in ${isCenterSlide ? 'z-0 opacity-0' : 'z-10 opacity-50'}`}
      >
        <div
          className="absolute size-full select-none rounded-[8px] bg-transparent transition-all duration-150 ease-in lg:rounded-[16px]"
          onClick={handleSwipeClick}
        />
      </div>

      <div
        className={`flex size-full rounded-[8px] bg-transparent lg:rounded-[16px] ${isCenterSlide ? '' : 'justify-between'}`}
        onClick={handleRedirectClick}
      >
        <div
          style={{ backgroundImage: `url(${image})`, userSelect: 'none' }}
          className={`relative flex w-full overflow-hidden rounded-[8px] bg-background bg-cover bg-center transition-all duration-150 first:bg-no-repeat lg:rounded-[16px]`}
        >
          <div
            id="card-mask"
            className={cn('absolute bottom-0 left-0 right-0 w-full', {
              'h-[25%] bg-gradient-to-t from-background': isCenterSlide,
              'top-0 h-full bg-background opacity-50': !isCenterSlide,
            })}
          />

          {isCenterSlide ? (
            <CardInfo logo={logo} title={title} hosts={hosts} />
          ) : (
            <EllipseClip maskSize={maskSize} />
          )}
        </div>
      </div>
    </div>
  )
})

function getCombinedHostNames(hosts: Host[]) {
  const names = hosts.map((host) => host.display_name || '').filter((name) => !!name)

  return combineNames(names)
}

function CardInfo({ logo, title, hosts }: Pick<Event, 'logo' | 'title' | 'hosts'>) {
  return (
    <div className="absolute bottom-[9px] flex w-full items-center gap-[10px] px-2 transition-all duration-150 ease-in-out md:bottom-[14px] md:gap-[14px] md:p-3 2xl:px-6">
      {logo && (
        <Avatar className="size-8 md:size-12 2xl:size-[54px]">
          <AvatarImage src={`${logo}`} />
        </Avatar>
      )}

      <div className="flex w-full flex-col gap-1 overflow-hidden">
        {title && (
          <div className="header truncate whitespace-nowrap text-[16px] tracking-[0.09px] md:text-[20px] 2xl:text-[30px]">
            {title}
          </div>
        )}

        {Array.isArray(hosts) && !!hosts.length && (
          <div className="body 2xl:body-1 line-clamp-2 break-words text-[8px] tracking-[0.2px] md:text-[12px]">
            Hosted by {getCombinedHostNames(hosts)}
          </div>
        )}
      </div>
    </div>
  )
}

function SideFadeOutMask() {
  const masks = [
    { id: 'side-mask-left', style: 'left-0 bg-gradient-to-r' },
    { id: 'side-mask-right', style: 'right-0 bg-gradient-to-l' },
  ]
  return masks.map((mask) => (
    <div
      key={mask.id}
      id={mask.id}
      className={`pointer-events-none absolute top-0 z-10 hidden h-full w-[288px] from-background 2xl:block ${mask.style}`}
    />
  ))
}

function EllipseClip({ maskSize }: Record<'maskSize', number>) {
  const positions = [
    { transform: 'rotate(0deg)', right: '-20px', bottom: 'auto', left: 'auto' },
    { transform: 'rotate(-90deg)', right: '-20px', bottom: '0', left: 'auto' },
    { transform: 'rotate(180deg)', right: 'auto', bottom: '0', left: '-20px' },
    { transform: 'rotate(90deg)', right: 'auto', bottom: 'auto', left: '-20px' },
  ]

  return (
    <>
      <div className="relative m-auto h-full w-[68%] bg-background sm:w-[72%] md:w-[calc(100%-130px)] lg:w-[calc(100%-125px)] 2xl:w-[calc(100%-175px)]">
        {positions.map((pos, index) => (
          <div
            key={index}
            className="absolute size-[20px] overflow-hidden"
            style={{
              right: pos.right,
              bottom: pos.bottom,
              left: pos.left,
              transform: pos.transform,
            }}
          >
            <div
              className="absolute size-[40px] rounded-lg lg:rounded-xl 2xl:rounded-2xl"
              style={{ boxShadow: '-25px -25px var(--background)' }}
            />
          </div>
        ))}
      </div>
    </>
  )
}
