import { useRef, MouseEvent, TouchEvent } from 'react'
import { useMotionValue, useSpring, useMotionTemplate } from 'framer-motion'

const useLookAtPointer = (rotationRange: number) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const x = useMotionValue(0)
  const y = useMotionValue(0)
  const xSpring = useSpring(x)
  const ySpring = useSpring(y)
  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`

  const handleMouseMove = (e: MouseEvent) => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()
    const width = rect.width
    const height = rect.height

    const mouseX = (e.clientX - rect.left) * rotationRange
    const mouseY = (e.clientY - rect.top) * rotationRange

    const rX = (mouseY / height - rotationRange / 2) * -1
    const rY = mouseX / width - rotationRange / 2

    x.set(rX)
    y.set(rY)
  }

  const handleTouchMove = (e: TouchEvent) => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()
    const width = rect.width
    const height = rect.height

    const touchX = e.touches[0].clientX - rect.left
    const touchY = e.touches[0].clientY - rect.top

    const rX = (touchY / height - 0.5) * rotationRange * -1
    const rY = (touchX / width - 0.5) * rotationRange

    x.set(rX)
    y.set(rY)
  }

  const handleMouseLeave = () => {
    x.set(0)
    y.set(0)
  }

  const handleTouchEnd = () => {
    x.set(0)
    y.set(0)
  }

  return {
    ref,
    transform,
    handleMouseMove,
    handleTouchMove,
    handleMouseLeave,
    handleTouchEnd,
  }
}

export default useLookAtPointer
