import { useState } from 'react'

export function useSlickSlide(count: number = 1, countOfRow: number = 1) {
  const [slideIndex, setSlideIndex] = useState(0)
  const maxIndex = Math.ceil(count / countOfRow) - 1

  function nextSlide() {
    setSlideIndex((index) => {
      if (index + 1 > maxIndex) return index
      return index + 1
    })
  }

  function prevSlide() {
    setSlideIndex((index) => {
      if (index === 0) return index
      return index - 1
    })
  }

  return {
    slideIndex,
    nextSlide,
    prevSlide,
    maxIndex,
  }
}
