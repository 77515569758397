import React from 'react'
import { motion } from 'framer-motion'
import { cn } from '@/lib/utils'
import useLookAtPointer from '@/hooks/useLookAtPointer'

const ROTATION_RANGE = 40
const THICK_LAYERS_COUNT = 10
const CARD_STYLE = `h-[303px] w-[235px] md:w-[386px] md:h-[498px] lg:w-[242px] lg:h-[310px] 2xl:w-[320px] 2xl:h-[412px] rounded-xl bg-cover bg-center`

type TiltCardProps = {
  backgroundUrl: string
  className?: string
}
function TiltCard({ backgroundUrl, className, ...props }: TiltCardProps) {
  const { ref, transform, handleMouseMove, handleTouchMove, handleMouseLeave, handleTouchEnd } =
    useLookAtPointer(ROTATION_RANGE)

  return (
    <motion.div
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={cn(
        `z-10 flex h-full w-full items-end justify-center bg-transparent lg:w-[294px] lg:items-center lg:pr-[52px] 2xl:w-[445px] 2xl:pr-[125px]`,
        className,
      )}
      {...props}
    >
      <motion.div
        className="relative size-fit"
        style={{ transformStyle: 'preserve-3d', transform }}
      >
        <ThickLayer type="normal" count={THICK_LAYERS_COUNT} backgroundUrl={backgroundUrl} />

        <div
          className={`${CARD_STYLE}`}
          style={{
            transform: 'translateZ(30px)',
            transformStyle: 'preserve-3d',
            backgroundImage: `url(${backgroundUrl})`,
          }}
        />

        <ThickLayer
          type="reflection"
          count={Math.round(THICK_LAYERS_COUNT / 5)}
          backgroundUrl={backgroundUrl}
        />
      </motion.div>
    </motion.div>
  )
}

export default TiltCard

type ThickLayerProps = {
  count: number
  backgroundUrl: string
  type?: 'normal' | 'reflection'
  className?: string
}
function ThickLayer({
  count,
  backgroundUrl,
  type = 'normal',
  className,
  ...props
}: ThickLayerProps) {
  return (
    <>
      {Array.from({ length: count }, (_, i) =>
        type === 'normal' ? (
          <div
            key={`thick_layer_${i}`}
            style={{
              transform: `translateZ(${3 * (i + 1)}px)`,
              backgroundImage: `url(${backgroundUrl})`,
              filter: `brightness(${80 + i * 2}%)`,
            }}
            className={cn(
              `absolute inset-0 rounded-xl bg-cover bg-center ${CARD_STYLE}`,
              className,
            )}
            {...props}
          />
        ) : (
          <div
            key={`reflection_layer_${i}`}
            style={{
              transform: `scaleY(-1) translateZ(${count * (i + 1)}px)`,
              backgroundImage: `url(${backgroundUrl})`,
              maskImage: 'linear-gradient(transparent 85%, white 100%)',
            }}
            className={cn(
              `${CARD_STYLE} absolute -bottom-full top-[105%] -scale-y-100 rounded-xl opacity-20 brightness-75 grayscale-[90%] backdrop-blur-2xl`,
              className,
            )}
            {...props}
          />
        ),
      )}
    </>
  )
}
