'use client'

import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/navigation'
import Autoplay from 'embla-carousel-autoplay'
import { Card, CardContent } from '@/components/ui/card'
import { Carousel, CarouselContent, CarouselItem, type CarouselApi } from '@/components/ui/carousel'
import ArrowButton from '@/components/ArrowButton'
import TiltCard from '@/components/TiltCard'
import ViverseButton from '@/components/ViverseButton'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'

type MKTBannerProps = {
  lists: ActivityShowcaseList[]
  autoScroll?: boolean
  autoScrollInterval?: number
  className?: string
}

function MKTBanner({
  lists,
  autoScroll = true,
  autoScrollInterval = 5,
  className,
}: MKTBannerProps) {
  const plugin = useRef(
    Autoplay({
      delay: autoScrollInterval * 1000,
      stopOnInteraction: true,
      active: autoScroll,
      stopOnMouseEnter: false,
    }),
  )

  const [api, setApi] = useState<CarouselApi>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [count, setCount] = useState(lists.length ?? 0)

  useEffect(() => {
    if (!api) return

    setCount(api.scrollSnapList().length)
    setCurrentIndex(api.selectedScrollSnap())

    api.on('select', () => {
      setCurrentIndex(api.selectedScrollSnap())
    })

    if (lists[api.selectedScrollSnap()]?.video || lists[currentIndex]?.video) {
      plugin.current.stop()
    } else {
      plugin.current.play()
    }
  }, [api, currentIndex, lists])

  const handleNext = () => {
    if (!api) return
    api.scrollNext()
  }

  const handlePrev = () => {
    if (!api) return
    api.scrollPrev()
  }

  return (
    <Carousel
      plugins={[plugin.current]}
      className={cn('group relative overflow-hidden', className)}
      opts={{ align: 'start', loop: true }}
      setApi={setApi}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={() => plugin.current.play()}
    >
      <CarouselContent className="h-[798px] pb-[38px] md:h-[1062px] lg:h-[720px] lg:pb-0 3xl:h-[906px] 4xl:h-[960px]">
        <BannerItems lists={lists} current={currentIndex} handleNext={handleNext} />
      </CarouselContent>

      <div
        id="carousel-order-dots"
        className="absolute bottom-0 flex h-[38px] w-full items-center justify-center gap-2 text-center text-sm text-vive-white"
      >
        {Array.from({ length: count }).map((_, index) => (
          <span
            key={`carousel-order-dots-${index}`}
            className={`${currentIndex === index ? 'w-6 rounded-lg bg-vive-brand-50' : 'aspect-square rounded-full bg-vive-primary-70'} h-[6px] transition-all duration-700 ease-in-out`}
          />
        ))}
      </div>
      {count > 1 && (
        <div className="pointer-events-none absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 justify-between opacity-0 transition-opacity duration-200 lg:w-[924px] lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 2xl:w-[1340px]">
          <ArrowButton direction="left" onClick={handlePrev} />
          <ArrowButton direction="right" onClick={handleNext} />
        </div>
      )}
    </Carousel>
  )
}

export default MKTBanner

type BannerItemProps = Pick<MKTBannerProps, 'lists'> & {
  current: number
  handleNext: () => void
}

function BannerItems({ lists, current, handleNext }: BannerItemProps) {
  return (
    <>
      {lists.map((item, index) => (
        <CarouselItem key={item.title} className="relative rounded-none bg-cover bg-center">
          {item.video ? (
            <video
              className="absolute inset-0 size-full object-cover"
              autoPlay
              muted
              playsInline
              onEnded={() => handleNext()}
              ref={(video) => {
                if (video) video.currentTime = 0 // Reset playback time
              }}
            >
              {item.video.webm && <source src={item.video.webm} type="video/webm" />}
              {item.video.mp4 && <source src={item.video.mp4} type="video/mp4" />}
            </video>
          ) : (
            <div
              className="absolute inset-0 size-full bg-cover bg-center"
              style={{ backgroundImage: `url(${item.background})` }}
            />
          )}

          <Card
            className={`${item.card ? '' : 'lg:pr-[294px] 2xl:pr-[445px]'} m-auto flex size-full max-w-[1340px] flex-col justify-end gap-9 rounded-none border-0 bg-transparent md:gap-8 lg:flex-row lg:justify-center lg:gap-0`}
          >
            {item.card && <TiltCard backgroundUrl={item.card} />}
            <ItemContent isActive={current === index} list={item} />
          </Card>

          <div
            id="carousel-card-shadow"
            className="absolute bottom-0 left-0 z-0 h-20 w-full bg-mkt-banner-shadow md:h-60 lg:h-[278px]"
          />
        </CarouselItem>
      ))}
    </>
  )
}

type ItemContentProps = {
  list: ActivityShowcaseList
  isActive: boolean
}

function ItemContent({ isActive, list }: ItemContentProps) {
  const router = useRouter()
  const { title, description, view_count, like_count, online_counts, action_link, action_title } =
    list

  const VIEWS_INFO = [
    { carouselCount: online_counts, label: 'online' },
    { carouselCount: view_count, label: 'views' },
    { carouselCount: like_count, label: 'likes' },
  ]

  const handleActionLinkClick = (actionLink: string) => {
    const currentDomain = window.location.origin
    if (actionLink.startsWith(currentDomain)) {
      router.push(actionLink)
    } else {
      window.open(actionLink, '_blank')
    }
  }

  return (
    <CardContent className="z-20 flex min-h-[295px] flex-col px-6 py-0 md:min-h-[312px] lg:mx-0 lg:w-[454px] lg:justify-center lg:px-0 lg:pt-20 2xl:w-[530px]">
      <div className="mb-2 overflow-hidden lg:mb-2.5">
        <p
          className={`header-2 line-clamp-2 break-words transition-all duration-700 ease-in lg:text-[64px] lg:font-extrabold ${isActive ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} `}
        >
          {title}
        </p>
      </div>

      <div className="overflow-hidden">
        <div
          className={`${isActive ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} transition-all duration-700 ease-in`}
        >
          <span className="subheader-1 lg:header-4 mb-3 line-clamp-3 break-words lg:mb-5 lg:text-vive-white">
            {description}
          </span>
          <div className="mb-3 flex gap-4 lg:mb-5">
            {VIEWS_INFO.map(
              (info, index) =>
                !!info.carouselCount && (
                  <div key={index} className="flex items-end gap-2">
                    <p className="header-4 md:header-3">{info.carouselCount}</p>
                    <span className="body-1 lg:subheader-1 pb-[2px] text-vive-white">
                      {info.label}
                    </span>
                  </div>
                ),
            )}
          </div>
          {action_link && (
            <ViverseButton
              size="extra"
              variant="extra"
              onClick={() => handleActionLinkClick(action_link)}
            >
              {action_title}
            </ViverseButton>
          )}
        </div>
      </div>
    </CardContent>
  )
}
