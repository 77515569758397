import { ComponentProps, PropsWithChildren } from 'react'
import { Button as UIButton } from '@/components/ui/button'
import { cn } from '@/lib/utils'

import ArrowLeft from '@/components/assets/icon_arrow_left.svg'
import ArrowRight from '@/components/assets/icon_arrow_right.svg'

const arrowButtonClassName = {
  true: 'text-vive-primary-60',
  false: 'text-vive-primary-0 hover:text-vive-white',
}

interface ArrowButtonProps extends PropsWithChildren<ComponentProps<typeof UIButton>> {
  direction: 'left' | 'right'
}

function ArrowButton({
  direction = 'left',
  disabled = false,
  className,
  ref,
  ...props
}: ArrowButtonProps) {
  return (
    <UIButton
      variant="icon"
      size="icon"
      className={cn(arrowButtonClassName[`${disabled}`], className)}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {direction === 'left' ? <ArrowLeft className="size-6" /> : <ArrowRight className="size-6" />}
    </UIButton>
  )
}

export default ArrowButton
