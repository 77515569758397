'use client'

import { useTranslations } from 'next-intl'

import HeroSection, { HeroSectionConfig } from '@/components/HeroSection'
import IconMrRoom from '@/components/assets/icon_mr_room.svg'
import IconGroundSetup from '@/components/assets/icon_ground_setup.svg'
import IconGoLive from '@/components/assets/icon_go_live.svg'
import IconCalendar from '@/components/assets/icon_calendar.svg'
import HostEventMobileWebm from '@/public/videos/landing-heros/host-event@mobile.webm'
import HostEventMobileMp4 from '@/public/videos/landing-heros/host-event@mobile.mp4'
import HostEventPcWebm from '@/public/videos/landing-heros/host-event@pc.webm'
import HostEventPcMp4 from '@/public/videos/landing-heros/host-event@pc.mp4'

function HostEventHeroSection() {
  const t = useTranslations()

  const props: HeroSectionConfig = {
    banner: {
      title: t('landing-page.host-event-title'),
      description: t('landing-page.host-event-description'),
      videos: {
        mobile: {
          webm: HostEventMobileWebm,
          mp4: HostEventMobileMp4,
        },
        pc: {
          webm: HostEventPcWebm,
          mp4: HostEventPcMp4,
        },
      },
    },
    infos: [
      {
        backgroundColor: 'blue',
        icon: <IconMrRoom />,
        title: t('landing-page.host-event-info-1-title'),
        description: t('landing-page.host-event-info-1-description'),
      },
      {
        backgroundColor: 'purple',
        icon: <IconGroundSetup />,
        title: t('landing-page.host-event-info-2-title'),
        description: t('landing-page.host-event-info-2-description'),
      },
      {
        backgroundColor: 'green',
        icon: <IconGoLive />,
        title: t('landing-page.host-event-info-3-title'),
        description: t('landing-page.host-event-info-3-description'),
      },
      {
        backgroundColor: 'blue',
        icon: <IconCalendar />,
        title: t('landing-page.host-event-info-4-title'),
        description: t('landing-page.host-event-info-4-description'),
      },
    ],
  }

  return <HeroSection className="mt-[120px] 2xl:mt-[200px]" variant="right" {...props} />
}

export default HostEventHeroSection
