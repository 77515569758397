'use client'

import { HTMLAttributes } from 'react'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'
import CarouselBanner from '@/components/CarouselBanner'
import ViverseButton from '@/components/ViverseButton'
import type { Event } from '@/services/cms'

interface EventsCarouselProps extends HTMLAttributes<HTMLDivElement> {
  lists: Event[]
}

function EventsCarousel({ lists, className, ...props }: EventsCarouselProps) {
  const t = useTranslations()
  const router = useRouter()

  const handleGoToExploreWorlds = () => {
    router.push('/events')
  }

  if (lists?.length < 1) return null

  return (
    <section className={className}>
      <CarouselBanner lists={lists} {...props} />
      <div className="mt-6 text-center md:mt-9">
        <ViverseButton size="large" variant="secondary" onClick={handleGoToExploreWorlds}>
          {t('landing-page.events-carousel-cta')}
        </ViverseButton>
      </div>
    </section>
  )
}

export default EventsCarousel
