'use client'

import { HTMLAttributes } from 'react'
import RollingCarousel from '@/components/RollingCarousel'
import LikedWorldCard, { LikedWorldCardProps } from '@/components/LikedWorldCard'
import { EditorChoice, Room } from '@/services/cms'
import { useLikedRoom } from '@/hooks/useLikedRoom'

const ROOM_COUNTS_LIMITS = 9

interface TopicalAreaProps extends HTMLAttributes<HTMLDivElement> {
  editorChoices: EditorChoice[]
}

function TopicalArea({ editorChoices, ...props }: TopicalAreaProps) {
  return (
    <section {...props}>
      {editorChoices.map(
        ({ id, title, rooms, description }) =>
          rooms?.length > ROOM_COUNTS_LIMITS && (
            <div
              className="flex flex-col items-center overflow-hidden"
              key={`topical-area-editor-choice-${id}`}
            >
              <div className="flex w-[310px] flex-col items-center gap-[12px] md:w-[540px] 2xl:w-[860px] 2xl:gap-[16px] 4xl:w-[2560px]">
                <div className="header-3 2xl:header-2 text-center">{title}</div>
                <div className="body-1 text-center">{description}</div>
              </div>
              <RoomsRollingCarousel rooms={rooms} />
            </div>
          ),
      )}
    </section>
  )
}

function getLeftRightRollingRooms(rooms: Room[]) {
  let leftRollingRooms: Room[] = [],
    rightRollingRooms: Room[] = []

  rooms.forEach((room, index) => {
    if (index >= rooms.length / 2) {
      leftRollingRooms.push(room)
    } else {
      rightRollingRooms.push(room)
    }
  })

  return [leftRollingRooms, rightRollingRooms]
}

function RoomsRollingCarousel({ rooms }: { rooms: Room[] }) {
  return getLeftRightRollingRooms(rooms).map((rollingRooms, index) => {
    const direction = index ? 'right' : 'left'

    return (
      <RollingCarousel direction={direction} key={`rolling-carousel-${direction}`}>
        {rollingRooms.map(
          ({ id, title, image, owner, hubs_url, is_live, online_counts, is_liked }) => {
            return (
              <div
                className="w-[248px] lg:w-[calc(206px_+_(100vw_-_1024px)_/_4)] 4xl:w-[590px]"
                key={`like-world-card-${id}`}
              >
                <LikedWorldCardWithDetails
                  title={title}
                  image={image}
                  owner={owner}
                  href={hubs_url}
                  roomId={id}
                  isLive={is_live}
                  liveCount={online_counts}
                  isLiked={is_liked}
                />
              </div>
            )
          },
        )}
      </RollingCarousel>
    )
  })
}

interface LikedWorldCardWithDetailsProps extends LikedWorldCardProps {
  roomId: Room['id']
}

function LikedWorldCardWithDetails({
  roomId,
  title,
  image,
  owner,
  href,
  isLive,
  liveCount,
  isLiked: initialIsLiked,
}: LikedWorldCardWithDetailsProps) {
  const { isLiked, handleClickLike } = useLikedRoom(roomId, href, initialIsLiked)

  return (
    <LikedWorldCard
      title={title}
      image={image}
      owner={owner}
      href={href}
      isLiked={isLiked}
      onClickLike={handleClickLike}
      isLive={isLive}
      liveCount={liveCount}
      hideLiked
    />
  )
}

export default TopicalArea
